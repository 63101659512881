<template>
  <router-view v-if="ready" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { errorLoggingService } from '@hypefactors/shared/js/services/errorLoggingService'

import Api from '@/services/Api'
import { Trans } from '@/services/TranslationService'
import { CrossAuth } from '@/services/CrossStorage'

export default {
  name: 'Bootstrapper',

  data () {
    return {
      ready: false,
      loading: null
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  created () {
    this.loading = this.$loading({
      lock: true,
      text: this.$t('Application is booting')
    })
  },

  async mounted () {
    await this.prepareApplication()
  },

  methods: {
    ...mapActions([
      'fetchCurrencies',
      'fetchLanguages',
      'setUser',
      'setUserBrands'
    ]),

    async fetchUser () {
      const user = await Api.getData('user')

      this.setUser(user)
    },

    async fetchUserBrands () {
      const brands = await Api.getData('user/brands')

      this.setUserBrands(brands)
    },

    // TODO: Improve this and attempt to reuse it...
    async setApplicationLanguage (language) {
      if (!language || (language && !Trans.isLangSupported(language))) {
        language = await CrossAuth.get('CURRENT_LANGUAGE').catch(e => false)
      }

      await Trans.changeLanguage(language || Trans.getUserSupportedLang())
    },

    async prepareApplication () {
      this.setApplicationLanguage()

      try {
        await Promise.all([
          this.fetchCurrencies(),
          this.fetchLanguages(),
          this.fetchUser(),
          this.fetchUserBrands()
        ])

        errorLoggingService.identify(this.currentUser)
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.ready = true

        this.loading.close()
      }
    }
  }
}
</script>
